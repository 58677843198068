var $ = require("jquery");
var uiWidget = require("../vendor/jquery.ui.widget"),
  iframeTransport = require("../vendor/jquery.iframe-transport"),
  fileupload = require("../vendor/jquery.fileupload"),
  uploadTemplate = require("../templates/upload-wrapper.hbs"),
  progressTemplate = require("../templates/upload-progress.hbs"),
  processingTemplate = require("../templates/upload-processing.hbs"),
  errorTemplate = require("../templates/upload-error.hbs"),
  zipTemplate = require("../templates/zip-choice.hbs"),
  doneMultipleTemplate = require("../templates/upload-done-multiple.hbs"),
  doneTemplate = require("../templates/upload-done.hbs"),
  videoTemplate = require("../templates/video-done.hbs");


module.exports = {

  init: function() {

    var self = this;

    var url = 'https://api2.transloadit.com/assemblies';

    $(document).on('click', '.form__uploaded-file-remove', function() {
      $(this).parents('.form__file-progress').remove();
      return false;
    });

    $('.auto__upload').each(function() {

      var self = this;


      var upload_model = $(this).data('upload-model');
      var upload_fields = $(this).data('upload-fields').split(',');
      var upload_preview = $(this).data('upload-preview');
      var upload_previews = upload_preview.split("|");
      var data_field_name = $(this).data('field-name');
      var preview_map = $(this).data('preview-map');
      var is_zip = $(this).data('zip');
      is_zip = (is_zip == 'true') || is_zip;
      var original_map = $(this).data('original-map');

      var is_document = $(this).data('document') == 'true';

      var isEditor = $(this).data('is-editor') == 1;
      var is_video = $(this).data('video');
      is_video = (is_video == 'true') || is_video;



      if (is_video) {
        upload_preview = upload_preview.split(",")[0];
        upload_previews = [upload_preview];
      }
      var upload_model_extra = '';
      if ($(this).data('upload-model-extra')) {
        upload_model_extra = "_" + $(this).data('upload-model-extra');
      }

      var upload_multiple = $(this).data('multiple');
      //window[upload_model + upload_model_extra + "_upload_params"].redirect_url = "http://a.dev/REDIRECT";

      var isImage = false;

      if ($(this).data('accept') == 'image') {
        isImage = true;
      }



      $(this).fileupload({
        url: url,
        dataType: 'json',
        // acceptFileTypes: filetypes,
        // forceIframeTransport: true,
        formData: {
          // redirect_url: 'http://a.dev/REDIRECT',
          params: JSON.stringify(window[upload_model + upload_model_extra + "_upload_params"]),
          signature: $(this).data('signature')
        },
        add: function(e, data) {
          if (isImage) {
            var filename = data.files[0].name;
            if (filename) {
              filename = filename.toLowerCase();
              var bits = filename.split(".");
              var extension = bits[bits.length - 1];
              if (extension != 'png' && extension != 'jpg' && extension != 'jpeg' && extension != 'bmp' && extension != 'gif') {
                alert("Please select a PNG, JPG or GIF file");
                return false;
              }
            }

          }
          // if existing...
          var existingProgressBox = $(this).parent().next('.form__file-progress');
          if (!upload_multiple && existingProgressBox) {
            existingProgressBox.remove();
          }

          var progressBox = $(uploadTemplate());
          $(this).parent().after(progressBox);
          data.context = progressBox;
          progressBox.empty().append(progressTemplate());
          progressBox.fadeIn();
          progressBox.find('.form__file-progress-progress').css('width', 0);
          data.submit();
        },
        done: function(e, data) {

          console.log("Done", data);
          // console.log("Upload done");
          var status = data.result.ok,
            executingJobs = data.result.executing_jobs;

          var isError = false,
            isFinished = false;

          if (executingJobs.length == 0 && status == 'ASSEMBLY_COMPLETED') {

            isError = true;
          } else {
            switch (status) {
              case 'ASSEMBLY_COMPLETED':
                isFinished = true;
                break;
            }
          }

          if (isError) {
            self.handleError(data.context);
          } else if (isFinished) {
            self.handleFinished(data.context, data.result);
          } else {
            data.context.empty().append(processingTemplate());

            self.handlePending(data.context, data.result.assembly_ssl_url);
          }

        },
        progress: function(e, data) {

          data.context.find('.form__file-progress-progress').css('width', Math.round(100 * data.loaded / data.total) + "%");
        }
      });

      this.handleError = function($resultBox) {
          if ($resultBox.parents('html').length == 0) {
            return;
          }
          if (!isEditor) {
            $resultBox.empty().append(errorTemplate());
          } else {
            $resultBox.empty();
          }

        },

        this.handlePending = function($resultBox, assembly_url) {
          var self = this;

          if ($resultBox.parents('html').length == 0) {
            return;
          }


          var checkStatus = function() {
            // console.log("About to check status", assembly_url);
            $.get('/uploadcheck?url=' + assembly_url, function(response) {

              var status = response.ok,
                executingJobs = response.executing_jobs;

              var isError = false,
                isFinished = false;

              if (false && executingJobs.length == 0) {
                isError = true;
              } else {
                switch (status) {
                  case 'ASSEMBLY_COMPLETED':
                    isFinished = true;
                    break;
                }
              }

              if (response.error) {
                isError = true;
              }

              if (isError) {
                self.handleError($resultBox);
              } else if (isFinished) {
                self.handleFinished($resultBox, response);
              } else {
                setTimeout(function() {
                  self.handlePending($resultBox, assembly_url);
                }, 1000);
              }

            }, "json");
          }

          checkStatus();
        },

        this.handleFinished = function($resultBox, result) {
          if ($resultBox.parents('html').length == 0) {
            return;
          }


          if (upload_previews.length > 1) {
            for (var i = 0; i < upload_previews.length; i++) {
              if (result.results[upload_previews[i]]) {
                upload_preview = upload_previews[i];
              }
            }
          }
          var results = result.results;

          var resultURLs = [];

          if (!results[upload_preview] && results[':original']) {
            results[upload_preview] = results[':original'];
          }
          var previewURL = upload_preview ? results[upload_preview][0].ssl_url : "";
          var previewMime = upload_preview ? results[upload_preview][0].mime : "";
          var previewExt = upload_preview ? results[upload_preview][0].ext : "";
          previewExt = previewExt.toLowerCase();
          var isImage = previewExt == 'png' || previewExt == 'jpg' || previewExt == 'gif' || previewExt == 'jpeg';


          var filesInZip = [];

          // console.log("Upload fields", upload_fields);

          for (var i = 0; i < upload_fields.length; i++) {

            if (is_zip) {

              paths = [];
              for (var j = 0; j < results[upload_fields[i]].length; j++) {
                var currFile = results[upload_fields[i]][j];
                if (currFile.ext == 'html' || currFile.ext == 'htm') {

                  filesInZip.push({
                    url: currFile.remote_path,
                    name: currFile.name
                  });
                }

              }
            } else {
              var fieldname = upload_fields[i];
              var resultname = fieldname;
              var suffix = "";
              if (resultname == ":original") {
                resultname = original_map;
                suffix = "";
              } else {
                resultname = data_field_name;
              }



              if (results[fieldname]) {
                if (preview_map != '' && preview_map != null && fieldname == upload_preview) {
                  resultURLs.push({
                    key: preview_map,
                    value: results[upload_fields[i]][0].ssl_url,
                    suffix: suffix
                  });


                } else {
                  resultURLs.push({
                    key: resultname,
                    value: results[upload_fields[i]][0].ssl_url,
                    suffix: suffix,
                    fieldname: fieldname
                  });
                }
              }
            }

          }

          if (isEditor) {
            if ("toolbarUploadHandler" in window) {
              window.toolbarUploadHandler(results[upload_fields[0]][0].ssl_url);
            }
            $resultBox.remove();

          } else
          if (is_zip) {


            var $newContent = $(zipTemplate({
              model: upload_model,
              filesInZip: filesInZip,
              key: data_field_name
            }));

            $resultBox.empty().append($newContent);


          } else if (is_video) {

            var videoWidth = results[upload_fields[0]][0].meta.width,
              videoHeight = results[upload_fields[0]][0].meta.height;
            console.log("Width", videoWidth, "Height", videoHeight);
            console.log(resultURLs);

            var $newContent = $(videoTemplate({
              model: upload_model,
              results: resultURLs,
              width: videoWidth,
              height: videoHeight
            }));

            $resultBox.empty().append($newContent);

          } else {
            var fileName = "";
            if (previewURL) {
              var bits = previewURL.split("/");
              fileName = bits[bits.length - 1];
              bits = fileName.split("_");
              bits.splice(0, 1);
              fileName = bits.join("_");
            }
            var template = upload_multiple ? doneMultipleTemplate : doneTemplate;
            var $newContent = $(template({
              model: upload_model,
              results: resultURLs,
              preview: previewURL,
              isImage: isImage,
              fileName: fileName
            }));

            if (previewURL == "") {
              $newContent.find('img').replaceWith("<p>Document uploaded</p>");
            }

            $newContent.find('img').css('opacity', 0);

            $resultBox.empty().append($newContent);

            $newContent.find('img').each(function() {

              $(this).on('load', function() {
                $(this).css('opacity', 1);
              });
            })
          }

        }

    });


  }



}
