var templater = require("handlebars/runtime")["default"].template;module.exports = templater({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <input type=\"hidden\" name=\""
    + alias1(container.lambda((depths[1] != null ? lookupProperty(depths[1],"model") : depths[1]), depth0))
    + "["
    + alias1(((helper = (helper = lookupProperty(helpers,"fieldname") || (depth0 != null ? lookupProperty(depth0,"fieldname") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"fieldname","hash":{},"data":data,"loc":{"start":{"line":12,"column":44},"end":{"line":12,"column":57}}}) : helper)))
    + alias1(((helper = (helper = lookupProperty(helpers,"suffix") || (depth0 != null ? lookupProperty(depth0,"suffix") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"suffix","hash":{},"data":data,"loc":{"start":{"line":12,"column":57},"end":{"line":12,"column":67}}}) : helper)))
    + "]\" value=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":12,"column":77},"end":{"line":12,"column":86}}}) : helper)))
    + "\"/>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n  \n  <video width=\"480\" controls >\n    <source src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"results") : depth0)) != null ? lookupProperty(stack1,"1") : stack1)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" type=\"video/webm\">\n    <source src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"results") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\" type=\"video/mp4\">\n    Your browser doesn't support HTML5 video tag.\n  </video>\n\n  <a href='#' class='form__uploaded-file-remove'><span class=\"fa fa-trash\"></span></a>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"results") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "  <input type=\"hidden\" name=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"model") || (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"model","hash":{},"data":data,"loc":{"start":{"line":14,"column":29},"end":{"line":14,"column":38}}}) : helper)))
    + "[width]\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"width") || (depth0 != null ? lookupProperty(depth0,"width") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"width","hash":{},"data":data,"loc":{"start":{"line":14,"column":54},"end":{"line":14,"column":63}}}) : helper)))
    + "\"/>\n  <input type=\"hidden\" name=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"model") || (depth0 != null ? lookupProperty(depth0,"model") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"model","hash":{},"data":data,"loc":{"start":{"line":15,"column":29},"end":{"line":15,"column":38}}}) : helper)))
    + "[height]\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"height") || (depth0 != null ? lookupProperty(depth0,"height") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"height","hash":{},"data":data,"loc":{"start":{"line":15,"column":55},"end":{"line":15,"column":65}}}) : helper)))
    + "\"/>\n</div>";
},"useData":true,"useDepths":true});