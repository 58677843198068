var $ = window.jQuery = require('jquery');
var UploadManager = require('./modules/UploadManager'),
    Quill = require('quill'),
    select2 = require('./vendor/select2');

$('.tag__select select').each(function () {

    $(this).select2({

        placeholder: $(this).attr('placeholder'),
        tags: $(this).data('createnew') == true,
        width: 'style'

    });
});

$('#cet-acecountries, #blogpost-acecountries').select2({
    placeholder: $(this).attr('placeholder'),
    width: 'style'
});

var toolbarConfig = {
    container: [
        [{
            'header': [1, 3, 4, 5, 6, false]
        }],
        ['bold', 'italic', 'underline'],
        [{
            'list': 'ordered'
        }, {
            'list': 'bullet'
        }],
        [{
            'script': 'sub'
        }, {
            'script': 'super'
        }],
        ['image', 'link'],
        ['clean']
    ],
    'handlers': {
        // handlers object will be merged with default handlers object
        'link': function (value) {
            if (value) {
                var href = prompt('Enter the URL');
                this.quill.format('link', href);
            } else {
                this.quill.format('link', false);
            }
        },
        'image': function (value) {

            $('.editor-upload .auto__upload').trigger('click');
            globaleditor = this.quill;
        }
    }
};

var globaleditor;


if ($('.make-rich').length > 0) {
    window.toolbarUploadHandler = function (url) {
        var selection = globaleditor.getSelection(true);
        globaleditor.insertEmbed(selection.index, 'image', url);
    }
}

const watchdog = new CKSource.EditorWatchdog();

window.watchdog = watchdog;

if ($('.make-rich').length > 0) {
    watchdog.setCreator((element, config) => {
        return CKSource.Editor
            .create(element, config)
            .then(editor => {
                return editor;
            });
    });

    watchdog.setDestructor(editor => {
        return editor.destroy();
    });

    watchdog.on('error', handleSampleError);

    watchdog
        .create(document.querySelector('.make-rich'), {
            removePlugins: ['Title', 'FontFamily', 'MediaEmbed'],
            mediaEmbed: {
                previewsInData:true
            },
            htmlSupport: {
                // more info here:
                // https://ckeditor.com/docs/ckeditor5/latest/features/html/general-html-support.html#enabling-all-html-features
                allow: [
                    {
                        classes: true,
                    }
                ]
            },
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    // h2 has been left out as it is white text and may confuse the clients, uncomment if needed in future
                    // { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                ]
            },
            link: {
                decorators: {
                    openInNewTab: {
                        mode: 'manual',
                        label: 'Open in a new tab',
                        defaultValue: true,
                        attributes: {
                            target: '_blank',
                            rel: 'noopener noreferrer'
                        }
                    }
                }
            }
        })
        .then(editor => {
            console.log('Editor was initialized');
        })
        .catch(handleSampleError);
}
function handleSampleError( error ) {
    console.log('Something went wrong: ' + error);
}

UploadManager.init();

